<!-- 申报管理 -->
<template>
  <div>
    <el-card class="box-card">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item>申报管理</el-breadcrumb-item>
        <el-breadcrumb-item>申报审核</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="hr-20"></div>
      <!-- 搜索组件 -->
      <div class="fl" style="width: 100%">
        <div class="searchtool">
          <el-form
            label-width="0"
            class="ruleForm"
            size="small"
            style="width: 100%;"
          >
            <v-row v-show="policy">
              <v-col cols="2">
                <el-form-item>
                  <el-input
                    v-model.trim="searchName"
                    placeholder="姓名"
                    clearable
                  />
                </el-form-item>
              </v-col>
              <v-col cols="2">
                <el-form-item>
                  <el-input
                    v-model.trim="enterpriseName"
                    placeholder="单位名称"
                    clearable
                  />
                </el-form-item>
              </v-col>
              <v-col cols="2">
                <el-form-item>
                  <el-select
                    v-model="currentStatus"
                    multiple
                    placeholder="状态"
                    @change="setStatus"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in StatusList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </v-col>
              <v-col cols="6">
                <el-form-item>
                  <el-date-picker
                    clearable
                    v-model="searchStartIntroductionTime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="申报时间"
                    end-placeholder="申报时间"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                  <el-button
                    type="primary"
                    plain
                    class="ml-4"
                    size="mini"
                    @click="getData"
                    icon="el-icon-search"
                    >查询
                  </el-button>
                  <el-button
                    type="primary"
                    plain
                    size="mini"
                    @click="requestRefresh2"
                    icon="el-icon-refresh"
                    >刷新
                  </el-button>

                  <el-button
                    type="warning"
                    plain
                    size="mini"
                    @click="exportSummary"
                    icon="el-icon-upload2"
                    >导出汇总表
                  </el-button>
                </el-form-item>
              </v-col>
            </v-row>
            <el-form-item v-if="!policy">
              <el-button
                type="primary"
                plain
                size="mini"
                @click="requestRefresh"
                icon="el-icon-refresh"
                >刷新
              </el-button>
              <span v-show="!policy" style="color: #333;float: right;"
                >今日收到申报数：{{ TodayNum }} 条</span
              >
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div v-if="policy === true">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @row-dblclick="requestSee"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            prop="policyTitle"
            label="标题"
            min-width="320px"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-tag size="small" type="danger" v-if="!scope.row.policyTitle">
                此申报对应政策表单已删除
              </el-tag>
              <span
                v-else
                @click="tiaowaim(scope.row)"
                style="cursor: pointer;"
              >
                {{ scope.row.policyTitle }}
              </span>
            </template>
          </el-table-column>

          <el-table-column prop="ownerName" label="申请人" min-width="80">
          </el-table-column>
          <el-table-column
            prop="enterpriseName"
            label="供职单位"
            min-width="240"
          >
          </el-table-column>
          <el-table-column label="申请时间" min-width="120" align="center">
            <template slot-scope="scope">{{
              scope.row.createTime | formatDate
            }}</template>
          </el-table-column>
          <el-table-column prop="status" min-width="100" label="状态">
            <template slot-scope="scope">
              <el-tag
                size="small"
                type="success"
                v-if="scope.row.currentStatus === 21"
                >已通过
              </el-tag>
              <el-tag
                size="small"
                type="success"
                v-else-if="scope.row.currentStatus === 11"
                >单位通过
              </el-tag>
              <el-tag
                size="small"
                type="success"
                v-else-if="scope.row.currentStatus === 10"
                >单位驳回
              </el-tag>
              <el-tag
                size="small"
                type="danger"
                v-else-if="scope.row.currentStatus === 20"
                >管理员驳回
              </el-tag>
              <el-tag
                size="small"
                type="success"
                v-else-if="scope.row.currentStatus === 1"
                >已提交
              </el-tag>
              <el-tag size="small" v-else>未通过 </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            min-width="80"
            label="个人/企业"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag
                size="small"
                type="success"
                v-if="scope.row.policyType === 'talent'"
                >个人
              </el-tag>
              <el-tag size="small" v-else>单位 </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="state"
            label="操作"
            min-width="160px"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                v-if="!scope.row.policyTitle"
                :disabled="true"
                >编辑
              </el-button>
              <!-- <span v-else>
                <el-button size="mini" @click="handleEdit(scope.row)"
                  >编辑
                </el-button>
                <el-button size="mini" @click="handlePreview(scope.row)"
                  >预览
                </el-button>

                <el-button
                  size="mini"
                  type="danger"
                  style="color: #f0f0f0"
                  v-show="scope.row.currentStatus === 21"
                  @click="openTurndown(scope.row)"
                  >再次驳回
                </el-button>
                <el-button
                  size="mini"
                  type="primary"
                  style="color: #f0f0f0"
                  v-show="scope.row.currentStatus === 21"
                  @click="openCapital(scope.row)"
                  >资金备注
                </el-button>
              </span> -->
              <el-dropdown v-else>
                <span class="el-dropdown-link">
                  <el-button type="text" size="mini" icon="el-icon-setting"
                    >更多操作
                  </el-button>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-button size="mini" @click="handleEdit(scope.row)"
                      >审核编辑
                    </el-button></el-dropdown-item
                  >
                  <el-dropdown-item
                    ><el-button size="mini" @click="handlePreview(scope.row)"
                      >查看预览
                    </el-button></el-dropdown-item
                  >
                  <el-dropdown-item v-show="scope.row.currentStatus === 21">
                    <el-button
                      type="danger"
                      size="mini"
                      style="color: #f0f0f0"
                      @click="openTurndown(scope.row)"
                      >再次驳回</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item v-show="scope.row.currentStatus === 21"
                    ><el-button
                      type="primary"
                      size="mini"
                      style="color: #f0f0f0"
                      @click="openCapital(scope.row)"
                      >资金备注</el-button
                    ></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 8px"></div>
        <el-button @click="back" class="fr" size="mini" type="primary" plain
          >返回
        </el-button>
      </div>

      <el-table
        :data="fileData"
        style="width: 100%"
        @row-click="openPolicy"
        v-if="policy === false"
      >
        <el-table-column prop="title" label="标题" min-width="360px">
        </el-table-column>
        <el-table-column prop="type" label="个人/企业" min-width="240px">
          <template slot-scope="scope">
            <el-tag
              size="small"
              type="success"
              v-if="scope.row.type === 'talent'"
              >个人
            </el-tag>
            <el-tag size="small" v-else>单位 </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型" min-width="240px">
          <template slot-scope="scope">
            {{ isaffairType(scope.row.applyTimes) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          min-width="240px"
          align="center"
        >
          <template slot-scope="scope">{{
            scope.row.createTime | formatDate
          }}</template>
        </el-table-column>
        <el-table-column
          prop="state"
          label="操作"
          min-width="240px"
          fixed="right"
          align="right"
        >
          <template>
            <el-button size="mini" plain>
              打开列表
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="hr-10"></div>
      <!-- 详情翻页 -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="requestPage"
        id="pagination"
        v-if="policy"
        :current-page="currentD"
      />
      <!-- 列表翻页 -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="current"
        :page-size="pageSize"
        @current-change="searchPage"
        v-else
      />
      <span style="float: left;margin: -40px 0 0 0;"
        >共计{{ total }}条数据</span
      >
    </el-card>

    <el-dialog
      title="资金备注"
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
      width="470px"
    >
      <el-form ref="form" v-if="dialogVisible" :model="form" label-width="80px">
        <el-form-item label="发放说明:">
          <el-input
            v-model="form.instructionIssued"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
        <el-form-item label="发放月份:">
          <el-date-picker
            @change="setMonth"
            v-model="commentMonth"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="发放金额:">
          <el-input v-model="form.amountIssued"></el-input>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input v-model="form.comment" type="textarea" :rows="2"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
        <el-button type="success" plain @click="requestCapital"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="驳回"
      :visible.sync="dialogTurndown"
      :destroy-on-close="true"
      width="560px"
    >
      <el-form
        ref="form"
        v-if="dialogTurndown"
        :model="Turnform"
        label-width="80px"
      >
        <el-form-item label="备注">
          <el-input type="textarea" v-model="Turnform.content"></el-input>
        </el-form-item>
      </el-form>
      <div class="buttons ">
        <el-button @click="dialogTurndown = false">关 闭</el-button>
        <el-button type="success" plain @click="requestTurn(20)"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="PDF预览"
      v-loading="loading"
      element-loading-text="拼命加载中"
      :visible.sync="dialogPdf"
      :destroy-on-close="true"
      width="80%"
    >
      <iframe
        :src="PdfUrl"
        frameborder="0"
        id="ifra"
        v-if="PdfUrl"
        height="600"
        width="100%"
      ></iframe>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPdf = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import sourceAxios from "axios";
import configDate from "@/utils/config";

export default {
  data() {
    return {
      sehs: true,
      butloading: false,
      createLoading: false,
      dialogVisible: false,
      dialogTurndown: false,
      isCreateAffair: false,
      dialogPdf: false,
      loading: false,
      PdfUrl: "",
      policy: false, // 是否查看详情 true 为查看 false 为列表
      commentMonth: "",
      form: {
        affairId: "",
        comment: "",
        amountIssued: "", //发放金额
        instructionIssued: "", //	发放说明
        startMonth: "", //	发放开始月份
        endMonth: "", //	发放结束月份
      },
      Turnform: {},
      currentStatus: [],
      searchName: "",
      enterpriseName: "",
      searchStartIntroductionTime: [],
      StatusList: [
        {
          value: 2,
          label: "未审核",
        },
        {
          value: 10,
          label: "单位驳回",
        },
        {
          value: 11,
          label: "单位通过",
        },
        {
          value: 21,
          label: "管理员通过",
        },
        {
          value: 20,
          label: "管理员驳回",
        },
      ],
      newForm: [],
      TodayNum: 0,
      title: "",
      page: 1,
      pages: 0,
      pageSize: 10,
      total: 0,
      status: 1,
      ldzj: false,
      currentD: 1,
      current: 1,
      tableData: [],
      fileData: [],
      policyId: "",
      policyType: "",
      policyTitle: "",
      multipleSelection: [],
      date1: "",
      ruleForm: {
        current: 1,
        size: 10,
        type: "",
        title: "",
        startTime: "",
        endTime: "",
      },
      typeData: {}, //类型列表
    };
  },
  created() {
    if (this.$route.query.policyId) {
      this.policy = true;
      this.policyId = this.$route.query.policyId;
      this.getData();
      this.geTtypeData();
    } else {
      this.getDate();
      this.gettodayNum();
      this.geTtypeData();
    }
  },

  methods: {
    setStatus(val) {},
    setMonth(val) {
      this.form.startMonth = this.$moment(val[0]).valueOf();
      this.form.endMonth = this.$moment(val[1]).valueOf();
    },
    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },

    // 刷新列表数据
    requestRefresh() {
      this.current = 1;
      this.getDate();
    },

    // 获取列表数据
    getDate() {
      this.gettodayNum();
      this.$axios
        .get("/policy/page", {
          params: {
            current: this.current,
            size: this.pageSize,
          },
        })
        .then((response) => {
          this.fileData = response.data.data.records;
          this.total = response.data.data.total;
          this.current = response.data.data.current;
        });
    },

    geTtypeData() {
      const self = this;
      this.$axios
        .get("/affairType/selectAll", {
          params: {
            pageNo: 1,
            pageSize: 100,
          },
        })
        .then(function(res) {
          if (res.data.status === 200) {
            self.typeData = res.data.data.records;
          }
        });
    },
    isaffairType(id) {
      let affairTypeTxt = "";
      // this.typeData.forEach((item) => {
      //   if (item.id === id) {
      //     affairTypeTxt = item.name;
      //   }
      // });
      // console.log(this.typeData);
      for (var i in this.typeData) {
        //console.log(this.typeData[i].id, id);
        if (this.typeData[i].id == id) {
          affairTypeTxt = this.typeData[i].name;
        }
      }
      return affairTypeTxt;
    },

    // 列表翻页
    searchPage(value) {
      this.current = value;
      this.getDate();
    },
    //  查看
    requestSee(row) {
      sessionStorage.setItem("current", this.current);
      let newPage = this.$router.resolve({
        path: "/sbgoverningwz/" + row.policyId + ":" + row.id,
      });
      window.open(newPage.href, "_blank");
    },
    // 刷新详情数据
    requestRefresh2() {
      this.currentD = 1;
      this.currentStatus = [];
      this.searchName = "";
      this.searchStartIntroductionTime = [];
      this.enterpriseName = "";
      this.getData();
    },

    exportSummary() {
      let url = "/affair/exportSummaryExcel";
      let status = this.currentStatus.length
        ? this.currentStatus.toString()
        : "20,11,21";
      let startTime = "";
      let endTime = "";
      if (!this.searchStartIntroductionTime) {
        startTime = this.searchStartIntroductionTime[0];
        endTime = this.searchStartIntroductionTime[1];
      }
      let path =
        configDate.url +
        url +
        "?currentStatus=" +
        status +
        "&policyId=" +
        this.policyId +
        "&enterpriseName=" +
        this.enterpriseName +
        "&name=" +
        this.searchName +
        "&startTime=" +
        startTime +
        "&endTime=" +
        endTime +
        "&type=talent";

      const link = document.createElement("a");
      link.style.display = "none";
      link.href = path;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // this.$axios.get(path).then((response) => {
      //   console.log(response);
      //   var blob = new Blob([response.data], {
      //     type: "application/vnd.ms-excel",
      //   });
      //   var url = window.URL.createObjectURL(blob);

      //   var a = document.createElement("a");
      //   a.href = url;
      //   a.download = this.policyTitle + ".xls";
      //   a.click();
      // });
    },
    // 获取详情
    getData() {
      let str = this.currentStatus.length
        ? this.currentStatus.toString()
        : "1,20,11,21";
      let startTime = "";
      let endTime = "";
      if (this.searchStartIntroductionTime) {
        startTime = this.searchStartIntroductionTime[0];
        endTime = this.searchStartIntroductionTime[1];
      }

      this.policyType = this.$route.query.policyType || this.policyType;
      this.$axios
        .get("/affair/page", {
          params: {
            current: this.currentD,
            size: this.pageSize,
            currentStatus: str,

            policyId: this.policyId,
            enterpriseName: this.enterpriseName,
            type: this.policyType,
            name: this.searchName,
            startTime: startTime,
            endTime: endTime,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            this.tableData = response.data.data.records;
            this.total = response.data.data.total;
            this.currentD = response.data.data.current;
            console.log(this.tableData[0].policyTitle);
            this.policyTitle = this.tableData[0].policyTitle;
          }
        });
    },

    // 详情翻页
    requestPage(value) {
      this.currentD = value;
      this.getData();
    },

    openPolicy(row) {
      this.policy = true;
      this.policyType = row.type;
      this.policyId = row.id;
      this.currentD = 1;

      this.$router.replace({
        path: "/sbgoverning",
        query: { policyId: row.id },
      });

      this.getData();
      this.policyType = row.type;
      console.log(this.policyType);
    },

    back() {
      this.policy = false;
      this.currentStatus = [];
      this.current = 1;
      this.getDate();
      this.$router.replace({
        path: "/sbgoverning",
        query: { policyId: "" },
      });
    },

    // 提交资金备注
    requestCapital() {
      let self = this;
      if (self.ldzj) return;
      self.ldzj = true;
      setTimeout(() => {
        self.ldzj = false;
      }, 2000);
      if (self.form.comment === "") {
        self.$root.warn("请输入资金备注！");
        return false;
      }
      if (self.isCreateAffair) {
        this.$axios.put("/comment", self.form).then((response) => {
          if (response.data.status === 200) {
            self.$root.success("资金备注修改成功！");
            self.dialogVisible = false;
          } else {
            self.$root.warn("资金备注修改失败！");
            self.dialogVisible = false;
          }
        });
      } else {
        this.$axios.post("/comment", self.form).then((response) => {
          if (response.data.status === 200) {
            self.$root.success("资金备注成功！");
            self.dialogVisible = false;
          } else {
            self.$root.warn("资金备注失败！");
            self.dialogVisible = false;
          }
        });
      }
    },

    // 打开资金备注
    openCapital(row) {
      this.form = {};
      this.dialogVisible = true;
      this.form.affairId = row.id;
      this.$axios.get("/comments?policyAffairId=" + row.id).then((response) => {
        if (response.data.status === 200) {
          response.data.data.forEach((item) => {
            if (item.affairId === row.id) {
              this.form = item;
              console.log(item);
              // this.form.startMonth = this.$moment(item.startMonth).format();
              // this.form.endMonth = this.$moment(item.endMonth).format();
              this.commentMonth = [
                this.$moment(item.startMonth).format(),
                this.$moment(item.endMonth).format(),
              ];
            }
          });
        }
        if (this.form.id !== undefined) {
          this.isCreateAffair = true;
        }
      });
    },
    openTurndown(row) {
      let { id, ownerId } = row;
      this.Turnform = {
        affairId: id,
        ownerId: ownerId,
        content: "",
        memo: "管理员驳回",
        status: 20,
      };
      console.log(this.Turnform);
      this.dialogTurndown = true;
    },

    requestTurn() {
      this.$axios
        .post("/affair/status", this.Turnform)
        .then((response) => {
          if (response.data.status === 200) {
            this.$root.success("办理成功");
            this.dialogTurndown = false;
            this.getData();
          } else {
            this.$root.warn("办理失败");
            this.dialogTurndown = false;
          }
        })
        .catch((error) => {
          this.dialogTurndown = false;
          console.error(error);
        });
    },

    handleEdit(row) {
      sessionStorage.setItem("affairId", row.id);
      sessionStorage.setItem("talentId", row.ownerId);
      sessionStorage.setItem("enterpriseId", row.enterpriseId);

      sessionStorage.setItem("current", this.current);

      this.$router.push({
        path: "/sbgoverningwz/" + row.policyId + ":" + row.id,
      });
      //this.PdfUrl = this.dialogPdf? configDate.url+'/export/preview?affairId='+row.id+'&talentId='+row.ownerId+'&enterpriseId='+row.enterpriseId:''
    },
    handlePreview(row) {
      let self = this;
      // console.log({
      //     affairId: row.id,
      //     talentId: row.ownerId,
      //     enterpriseId: row.enterpriseId
      // })
      this.loading = true;
      this.dialogPdf = true;
      this.PdfUrl = this.dialogPdf
        ? configDate.url +
          "/export/preview?affairId=" +
          row.id +
          "&talentId=" +
          row.ownerId +
          "&enterpriseId=" +
          row.enterpriseId
        : "";
      setTimeout(function() {
        self.loading = false;
        console.log("0");
      }, 3000);
      // let iframe = document.createElement('iframe')
      // iframe.onload = () => {
      //     console.log('加载完成') // 这里偶尔不会触发
      // }
    },
    tiaowaim(row) {
      let newPage = this.$router.resolve({
        path: "/declareitem/declarewz/" + row.policyId,
      });
      window.open(newPage.href, "_blank");
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    searchSubmit() {
      let self = this;
      this.ruleForm.startTime = this.date1[0]
        ? this.$moment(this.date1[0]).format("yyyy-MM-DD")
        : "";
      this.ruleForm.endTime = this.date1[1]
        ? this.$moment(this.date1[1]).format("yyyy-MM-DD")
        : "";
      this.searchPage(1);
      this.sehs = false;
      this.butloading = true;
      setTimeout(function() {
        self.butloading = false;
      }, 1200);
    },
    gettodayNum() {
      /* this.$axios.get('/affair/page', {
              params: {
                current: 1,
                size: 10,
                type: '',
                title: "",
                startTime: this.$moment().format('YYYY-MM-DD'),
                endTime: this.$moment().format('YYYY-MM-DD')
              }
            }).then(response => {
              if (response.data.status === 200) {
                this.TodayNum = response.data.data.total
              } else {
                this.TodayNum = 0
              }
            }) */
      this.$axios
        .get("/affair/count", {
          params: {
            currentStatus: "1,11,20,21",
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            this.TodayNum = response.data.data;
          } else {
            this.TodayNum = 0;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
#pagination {
  margin: 32px 0 16px 0;
}

.collapse {
  margin-top: 96px;
}

.searchtool {
  //background: #1090e7;
  height: 50px;
  text-align: left;
  line-height: 50px;
  padding: 0 0 0 16px;
  color: #fff;
  font-size: 16px;

  .ruleForm {
    float: left;
  }
}
</style>
